<!-- Finder particular de Apds -->

<template>
  <div class="muni_F" v-if="schema">
    <v-sheet :elevation="4">

      <!-- Cabecera -->
      <div class="cabecera" v-if="Entorno.header.header">       
        <base_Header 
          :Entorno="Entorno.header"
          @onEvent="event_Header">          
        </base_Header>
      </div>

      <!-- Contenido -->
      <div class="contenedor"> 

        <!-- Filtro de Busqueda -->
        <!-- <div @focusin="focusinFiltro()" :style="Entorno.filtro.style" :class="Entorno.filtro.class"> -->
        <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
          <div class="columna">

            <div style="display:flex">
              <v-text-field   
                style="flex: 0 0 40%"            
                v-bind="$input"
                v-model="schema.ctrls.name.value"
                :label="schema.ctrls.name.label"
                @keypress.13="event_search('buscarMunicipio')"> 
              </v-text-field>

              <v-btn v-bind="$cfg.btn.busca" @click="event_search('buscarMunicipio')">
                <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
              </v-btn> 
            </div>
            
          </div>
        </div>

        <!-- Grid -->
        <base_Fgrid :padre="stName" :Entorno="Entorno" @onEvent="event_Grid">
          <!-- Slot botonera Top -->
          <template v-slot:top_btns="{ Sitem }">         
            <div style="padding:10px 0 0 10px;">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="top"
                @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </div>
          </template>
          

          <!-- Slot campo acciones del Grid -->
          <template v-slot:acciones_grid="{ Sitem }">
            <btracombi
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="mrow"
              @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
            </btracombi>
          </template>
        </base_Fgrid>
      </div>
    </v-sheet>
  </div>
</template>


<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base"); 
  const base_Fgrid = () => plugs.groute("base_Fgrid.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");  

  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btracombi },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },


    data() {
      return {
        schema:null,
        api:'muni_F',
        stName:'stFmuni',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,

        headers: {},
      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno 
        this.Entorno.header.titulo = "Búsqueda de Municipio";  

        this.headers = {
          header: [
            { text: "Municipio", value: "name", witdh: "10rem" },
            { text: "Provincia", value: "prov", witdh: "10rem" },
            { text: "C.Postal", value: "cp", witdh: "10rem" },           
            { text: "Acciones", value: "acciones", sortable: false, width: "auto" }
          ],

        };

        this.Entorno.grid.headers = this.set_headers();       
      },  


      // gestor de eventos
      event_search(nameBtn = "", header) {
        var ctrls= this.schema.ctrls;

        if (ctrls.name.value.length< 3) {
          this.$root.$alert.open('Debe introducir más de 2 caracteres para la búsqueda', 'info');
          return;
        }

        this.buscarExec(nameBtn, header);     
      }
    }
  };
</script>
